<template>
  <v-footer height="360" color="#151C29" class="justify-center align-start">
    <v-container class="pa-0 footer-container">
      <v-row class="ma-0 mt-5" :class="isIE == true ? 'isIE' : ''">
        <v-col cols="3" class="d-flex justify-start pa-0">
          <v-card color="transparent" flat class="pt-2">
            <v-img class="ml-n1" width="160" alt="logo" contain src="@/assets/login/logo_bai.png" />
            <v-card-text class="pa-0 mt-14">
              <v-icon dense style="color: #AEB8C8">fas fa-phone-alt</v-icon>
              <span class="ml-2 font-size-16" style="color: #AEB8C8">4006189949</span>
            </v-card-text>
            <v-card-text class="pa-0 mt-5">
              <v-icon dense style="color: #AEB8C8">fas fa-map-marker-alt</v-icon>
              <span class="ml-3 font-size-14" style="color: #AEB8C8">北京朝阳区广渠路3号竞园艺术中心商8</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2" class="d-flex justify-center pa-0" style="height: 180px;">
          <v-divider vertical dark></v-divider>
        </v-col>
        <v-col cols="3" class="pa-0">
          <v-card color="transparent" flat class="pt-2">
            <v-card-text class="pa-0 font-size-16 font-weight-bold color-FFF">产品与服务</v-card-text>
            <div class="footer-divider"></div>
            <div class="d-flex mt-5">
              <v-card color="transparent" flat :class="index > 0 ? 'ml-15':''" v-for="(footerLink, index) in serviceProducts" :key="index">
                <v-hover v-slot="{ hover }" v-for="(linkInfo, linkIndex) in footerLink" :key="linkIndex">
                  <v-card-text
                    class="pa-0 content-info cursor-pointer"
                    @click.stop="handleFooterClick(linkInfo.router,linkInfo.isNewWindow,linkInfo.outerChain)"
                    :class="[linkIndex > 0 ? 'py-2':'pb-1',hover ? 'bg-hover':'']"
                  >{{ linkInfo.name }}</v-card-text>
                </v-hover>
              </v-card>
            </div>
          </v-card>
        </v-col>
        <v-col cols="3" class="pa-0">
          <v-card color="transparent" flat class="pt-2 ml-8">
            <v-card-text class="pa-0 font-size-16 font-weight-bold color-FFF">解决方案</v-card-text>
            <div class="footer-divider"></div>
            <div class="d-flex mt-5">
              <v-card color="transparent" flat :class="index > 0 ? 'ml-12':''" v-for="(footerLink, index) in solutions" :key="index">
                <v-hover v-slot="{ hover }" v-for="(linkInfo, linkIndex) in footerLink" :key="linkIndex">
                  <v-card-text
                    class="pa-0 content-info cursor-pointer"
                    @click.stop="handleFooterClick(linkInfo.router,linkInfo.isNewWindow,linkInfo.outerChain)"
                    :class="[linkIndex > 0 ? 'py-2':'pb-1',hover ? 'bg-hover':'']"
                  >{{ linkInfo.name }}</v-card-text>
                </v-hover>
              </v-card>
            </div>
          </v-card>
        </v-col>
        <v-col cols="1" class="pa-0">
          <v-card color="transparent" flat class="mt-5">
            <v-card-text class="d-flex justify-center pa-0 qr-code">
              <v-img alt="二维码" contain src="https://h5.ophyer.cn/official_website/other/new-ewm.png" width="108" max-width="108" height="108" max-height="108" />
            </v-card-text>
            <v-card-text class="d-flex justify-center pa-0 mt-3 content-info font-size-16">官方公众号</v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="width-per-100 d-flex justify-center align-center" style="position: absolute;bottom: 0;left: 0;height: 60px;background-color: #090E19">
        <div class="max-width-1200" style="color: #969799;font-size: 12px;">
          <!--© COPYRIGHT 2021 . ALL RIGHTS RESERVED. 北京飞天云动科技股份有限公司版权所有-->
          © COPYRIGHT 2022 . ALL RIGHTS RESERVED.
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502037637" target="_blank">京公网安备11010502037637号</a>
          <a href="https://beian.miit.gov.cn/" target="_blank" style="margin-left:5px">京ICP备09083236号-2</a>
        </div>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
import { browserVersion } from '@/utils/utils'
export default {
  name: 'OfficialWebsiteFooter',
  data: () => ({
    serviceProducts: [
      [
        {
          name: 'FT引擎',
          router: '/numberProducts/FT'
        },
        {
          name: 'SaaS',
          router: '/numberProducts/SaasUGC'
        },
        {
          name: '硬件产品',
          router: '/numberProducts/Hardware'
        },
        {
          name: '数字人',
          router: '/numberProducts/DigitalMan'
        },
        /* {
          name: '数字资产',
          router: '/numberProducts/DigitalAssets'
        } */
      ]
    ],
    solutions: [
      [
        {
          name: '娱乐',
          router: '/numberSolutions/Entertainment'
        },
        {
          name: '文博和旅游',
          router: '/numberSolutions/CulturalTourism'
        },
        {
          name: '新零售',
          router: '/numberSolutions/NewRetail'
        },
        {
          name: '房地产',
          router: '/numberSolutions/RealEstate'
        },
        {
          name: '工业制造',
          router: '/numberSolutions/IndustrialManufacturing'
        },
        {
          name: '职业培训',
          router: '/numberSolutions/VocationalTraining'
        }
      ],
      [
        {
          name: '元宇宙解决方案',
          router: '/numberSolutions/MetaverseSolution'
        },
        {
          name: '媒体运营',
          router: '/numberSolutions/MediaOperation'
        }
      ]
    ],
    isIE: browserVersion()
  }),
  mounted() {

  },
  methods: {
    handleFooterClick: function (router, isNewWindow, outerChain) {
      if (outerChain) {
        window.open(outerChain)
      } else if (router) {
        if (isNewWindow) {
          window.open(window.location.protocol + '//' + window.location.host + router)
        } else {
          this.$router.push(router)
        }
      }
    }
  }
}
</script>


<style lang="scss" scoped>
a {
  text-decoration: none !important;
  color: #969799 !important;
}

footer {
  position: relative;
  z-index: 6;
  .footer-container {
    width: 100%;
    max-width: 1200px;
    .isIE {
      width: 1200px;
      margin: 0 auto;
      margin-left: -230px !important;
    }
    .content-info {
      color: #aeb8c8;
      font-size: 12px;
    }
    .footer-divider {
      width: 64px;
      height: 1px;
      background: #aeb8c8;
      margin-top: 8px;
    }
    ::v-deep .qr-code {
      .v-image__image--contain {
        width: 108px;
      }
    }
  }
}
</style>
